var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ejs-dialog',{ref:"historyPopup",attrs:{"header":"변경이력","allowDragging":true,"showCloseIcon":true,"isModal":true,"close":_vm.onHistoryPopupClose,"width":"480","height":"600","animationSettings":{ effect: 'None' }}},[_c('div',{staticClass:"window"},[_c('div',{staticClass:"windowContent"},[_c('div',{staticClass:"content-wrapper"},[_c('div',{staticClass:"content-body"},[_c('article',{staticClass:"body-article"},[_c('section',{staticClass:"article-section"},[_c('div',{staticClass:"section-body",staticStyle:{"border-top-color":"#000"}},_vm._l((_vm.$_histories),function(history,index){return _c('div',{key:("histories-" + index)},[_vm._l((['create', 'update', 'delete']),function(method){return [(history[method])?_c('div',{key:("history-" + index + "-" + method)},[_c('div',{staticStyle:{"border-bottom":"1px solid #e0e0e0"}},[_c('div',{staticStyle:{"background-color":"#f9f9f9","padding":"9px 18px 8px 18px","color":"#000"}},[_vm._v(" ▷ "+_vm._s(_vm.getMethodName(method))+" ")]),_c('div',{staticStyle:{"padding":"6px 18px"}},[_c('div',{staticStyle:{"color":"#000","padding":"9px 0 8px 0","border-bottom":"1px solid #e0e0e0"}},[_c('div',{staticStyle:{"box-sizing":"border-box","float":"left","padding-right":"6px"}},[_vm._v(" "+_vm._s(history.workerName)+" ")]),_c('div',{staticStyle:{"box-sizing":"border-box","float":"right","padding-left":"6px"}},[_vm._v(" "+_vm._s(history.timestamp)+" ")]),_c('div',{staticStyle:{"clear":"both","height":"0"}})]),_c('div',{staticStyle:{"padding":"9px 0 8px 0"}},[_vm._l((_vm.getKeysOfObject(
                                  history[method]
                                )),function(type){return [(history[method][type])?_c('div',{key:("history-" + index + "-" + method + "-" + type)},_vm._l((history[method][type]),function(typeHistory,typeHistoryIndex){return _c('div',{key:("history-" + index + "-" + method + "-" + type + "-" + typeHistoryIndex)},[_c('div',{staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.getTypeName(type, typeHistory))+" ")]),_vm._l((_vm.getKeysOfObject(
                                        typeHistory
                                      )),function(typeHistoryId){return _vm._l((typeHistory[typeHistoryId]
                                          .changes),function(typeHistoryChange,typeHistoryChangeIndex){return _c('div',{key:("history-" + index + "-" + method + "-" + type + "-" + typeHistoryIndex + "-" + typeHistoryId + "-" + typeHistoryChangeIndex)},[(
                                            method !== 'delete' ||
                                            _vm.getFieldOldValue(
                                              type,
                                              typeHistoryChange
                                            ) !== ''
                                          )?[_c('div',{staticStyle:{"box-sizing":"border-box","float":"left","width":"153px","color":"#000","padding-right":"6px"}},[(
                                                typeHistoryChangeIndex ===
                                                  0 &&
                                                _vm.getFieldLabel(
                                                  type,
                                                  typeHistoryChange
                                                )
                                              )?[_vm._v(" "+_vm._s(_vm.getFieldLabel( type, typeHistoryChange ))+" ")]:void 0],2),_c('div',{staticStyle:{"box-sizing":"border-box","float":"left","width":"103px","color":"#000","padding-right":"6px"}},[_vm._v(" "+_vm._s(_vm.getFieldName( type, typeHistoryChange ))+" ")]),_c('div',{staticStyle:{"box-sizing":"border-box","float":"left","width":"calc(100% - 256px)","color":"#666","padding-left":"6px"},style:({
                                              textDecoration:
                                                method === 'delete'
                                                  ? 'line-through'
                                                  : undefined,
                                            })},[_vm._v(" "+_vm._s(_vm.getFieldOldValue( type, typeHistoryChange ))+" "),(method !== 'delete')?[_vm._v(" → "+_vm._s(_vm.getFieldNewValue( type, typeHistoryChange ))+" ")]:_vm._e()],2),_c('div',{staticStyle:{"clear":"both","height":"0"}})]:_vm._e()],2)})})],2)}),0):_vm._e()]})],2)])])]):_vm._e()]})],2)}),0)])])])])]),_c('div',{staticClass:"windowFooter"},[_c('ul',{staticClass:"button"},[_c('li',{staticClass:"close"},[_c('erp-button',{attrs:{"button-div":"CLOSE"},nativeOn:{"click":function($event){return _vm.onCloseButtonClick($event)}}},[_vm._v(" 닫기 ")])],1)])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }