import request from "@/utils/request";
const qs = require("qs");

/**
 * @return Promise
 */
export function getGroupList(searchConditions, isBackground = false) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/reservation_management/group_management/",
    method: "get",
    isBackground: isBackground,
    params: {
      grpKind:
        searchConditions.grpKind === "" || searchConditions.grpKind === "%"
          ? null
          : searchConditions.grpKind,
      searchValue:
        searchConditions.searchValue === ""
          ? null
          : searchConditions.searchValue,
      useFlag:
        searchConditions.useFlag === undefined ||
        searchConditions.useFlag === null
          ? true
          : searchConditions.useFlag,
      resveDateFrom: searchConditions.resveDateFrom,
      resveDateTo: searchConditions.resveDateTo,
    },
  });
}

/**
 * @return Promise
 */
export function getGroupInfoList(
    searchValue,
    grpKind,
    grpDiv,
    resveMm,
    useFlag,
    teamCnt,
    resveCycle,
    dwCode,
    grpHopeTime,
    areaCode,
    grpCharct,
    isDepositMoney
) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/reservation_management/group_management/group_info",
    method: "get",
    params: {
      searchValue,
      grpKind,
      grpDiv,
      resveMm,
      useFlag,
      teamCnt,
      resveCycle,
      dwCode,
      grpHopeTime,
      areaCode,
      grpCharct,
      isDepositMoney
    },
  });
}

/**
 * @return Promise
 */
export function getGroupInfoDetail(grpNo) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/reservation_management/group_management/group_info_detail",
    method: "get",
    params: {
      grpNo,
    },
  });
}

/**
 * @return Promise
 */
export function postGroupDetail(groupInfoMthTimeRequest) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/reservation_management/group_management/",
    method: "post",
    data: groupInfoMthTimeRequest,
  });
}

/**
 * @return Promise
 */
export function putGroupDetail(groupInfoMthTimeRequest) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/reservation_management/group_management/",
    method: "put",
    data: groupInfoMthTimeRequest,
  });
}

/**
 * @return Promise
 */
export function deleteGroupDetail(grpNo) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/reservation_management/group_management/",
    method: "delete",
    params: {
      grpNo,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  });
}
