import {
  groupBy as _groupBy,
  sortBy as _sortBy,
  flatten as _flatten,
  zipObject as _zipObject,
} from "lodash";
import { v4 as createUUID } from "uuid";
import { deepDiffs } from "@/utils/ObjectUtil";

/*
 * rawHistories = [
 *   {
 *     type: "", // slip, sale, memo
 *     idField: [""], // id
 *     record: {
 *       ...[type],
 *       insertName,
 *       updateName,
 *       rowStartDateTime,
 *       rowEndDateTime
 *     }
 *   },
 *   ...
 * ]
 *
 * histories = [
 *   {
 *     timestamp: "",
 *     workerName: "",
 *     create: {
 *       Slip: [
 *         {
 *           ...[rawHistory],
 *           historyId: "", // uuid
 *           tid: "", // uuid
 *           username: "", // 남재근
 *           timestamp: "", // 2021-01-01 00:00:00
 *           method: "", // create/update/delete
 *           changes: [
 *             {
 *               field: "",
 *               oldValue: "",
 *               newValue: ""
 *             }
 *           ]
 *         }
 *         ...
 *       ],
 *       Sale: [...],
 *       SlipMemo: [...],
 *       ...
 *     },
 *     [method]: {
 *       [type]: [history]
 *     }
 *   },
 *   ...
 * ]
 *
 * history {
 *   ...[rawHistory],
 *   historyId: "", // uuid
 *   tid: "", // uuid
 *   username: "", // 남재근
 *   timestamp: "", // 2021-01-01 00:00:00
 *   method: "", // create/update/delete
 *   changes: [
 *     {
 *       field: "",
 *       oldValue: "",
 *       newValue: ""
 *     }
 *   ]
 * }
 */
export function interpretRawHistories(rawHistories = []) {
  try {
    const rowStartDateTimes = rawHistories.map(
      ({ record: { rowStartDateTime } }) => rowStartDateTime
    );

    // 작업 id 생성
    const tidMap = _zipObject(
      rowStartDateTimes,
      rowStartDateTimes.map(() => createUUID())
    );

    // 1. (타입:아이디)별 기록 분류
    const groupByTypeAndId = _groupBy(
      // 각 기록에 대한 ID 부여
      rawHistories,
      ({ type, idField, record }) =>
        `${type}:${idField.map((i_idField) => record[i_idField]).join("_")}`
    );

    // 2. (타입:아이디)별 기록 시간순 정렬
    Object.keys(groupByTypeAndId).forEach((typeAndId) => {
      const histories = groupByTypeAndId[typeAndId];

      groupByTypeAndId[typeAndId] = _sortBy(
        histories,
        ({ record: { rowStartDateTime } }) => rowStartDateTime
      );
    });

    const groupByTimestamp = _groupBy(
      _flatten(
        Object.keys(groupByTypeAndId).map((typeAndId) => {
          const historiesOfGroup = groupByTypeAndId[typeAndId];

          return _flatten(
            historiesOfGroup.map((history, index) => {
              const { record: oldValues } =
                index === 0 ? { record: {} } : historiesOfGroup[index - 1];
              const { record: newValues } = history;

              const {
                updateId,
                insertName,
                updateName,
                rowStartDateTime,
                rowEndDateTime,
              } = history.record;

              // 3. 시간순 변동사항을 추출
              const changes = deepDiffs(oldValues, newValues) ? Object.keys(deepDiffs(oldValues, newValues))
                .map((field) => ({
                  field,
                  oldValue: oldValues[field],
                  newValue: newValues[field],
                }))
                .filter(
                  ({ field, oldValue, newValue }) =>
                    !(
                      (oldValue === undefined && newValue === null) ||
                      (oldValue === null && newValue === undefined)
                    ) && !["rowStartDateTime", "rowEndDateTime"].includes(field)
                ) : [];

              // deleted record
              if (index === historiesOfGroup.length - 1 && !!rowEndDateTime) {
                const currentHistory = {
                  ...history,
                  typeAndId,
                  historyId: createUUID(),
                  tid: tidMap[rowStartDateTime],
                  username: !updateId ? insertName : updateName,
                  timestamp: rowStartDateTime,
                  method: index === 0 ? "create" : "update",
                  changes,
                };
                const deleteHistory = {
                  ...history,
                  typeAndId,
                  historyId: createUUID(),
                  tid: tidMap[rowStartDateTime],
                  username: !updateId ? insertName : updateName,
                  timestamp: rowEndDateTime,
                  method: "delete",
                  changes: Object.keys(history.record).map((field) => ({
                    field,
                    oldValue: history.record[field],
                  })),
                };

                return [currentHistory, deleteHistory];
              }

              return {
                ...history,
                typeAndId,
                historyId: createUUID(),
                tid: tidMap[rowStartDateTime],
                username: !updateId ? insertName : updateName,
                timestamp: rowStartDateTime,
                method: index === 0 ? "create" : "update",
                changes,
              };
            })
          );
        })
      ),
      "timestamp"
    );
    return Object.keys(groupByTimestamp)
      .sort()
      .reverse()
      .map((timestamp) => {
        const groupByMethod = _groupBy(groupByTimestamp[timestamp], "method");
        Object.keys(groupByMethod).forEach((method) => {
          groupByMethod[method] = _groupBy(groupByMethod[method], "type");
        });

        const { insertName, updateName } = groupByTimestamp[
          timestamp
        ][0].record;

        return {
          ...groupByMethod,
          timestamp,
          workerName: updateName || insertName,
        };
      });
  } catch (e) {
    console.error(e);
  }

  return [];
}
