<template>
  <div id="holll">
    <ejs-dialog
      ref="groupPopup"
      :header="`골프단체 검색`"
      :allowDragging="false"
      :showCloseIcon="true"
      width="1180"
      height="800"
      :animationSettings="{ effect: 'None' }"
      :isModal="true"
      :close="onPopupClosed"
      enableResize="true"
    >
      <div class="window golfGroupSearch">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookup">
              <div class="lookup-left">
                <ul class="lookup-condition">
                  <li class="field groupType">
                    <div class="title">단체종류</div>
                    <ul class="content">
                      <li class="item">
                        <ejs-dropdownlist
                          v-model="groupSearchData.grpKind"
                          :dataSource="grpKindOptions"
                          :allowFiltering="false"
                          :fields="{ text: 'comName', value: 'comCode' }"
                          :tabindex="-1"
                          cssClass="lookup-condition-dropdown"
                        />
                      </li>
                    </ul>
                  </li>
                  <li class="field groupName">
                    <div class="title">단체명</div>
                    <ul class="content">
                      <li class="item">
                        <input-text
                          ref="searchValueTextBox"
                          id="searchValueTextBox"
                          v-model="groupSearchData.searchValue"
                          @keydown.enter="onSearchValueKeyDownEnter"
                        />
                      </li>
                    </ul>
                  </li>
                  <li class="field">
                    <ul class="content">
                      <li class="item check">
                        <ul class="check">
                          <li>
                            <label>
                              <input
                                type="checkbox"
                                id="useFlag"
                                v-model="groupSearchData.useFlag"
                                value="true"
                              />
                              <i></i>
                              <div class="label">사용여부</div>
                            </label>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
                <div class="lookup-lookup">
                  <erp-button
                      button-div="GET"
                      :ignore="isPopupOpened"
                      :is-shortcut-button="true"
                      v-on:click.native="onSearchGolfGroupClicked()"
                  >
                    조회
                  </erp-button>
                </div>
              </div>
            </div>
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-body">
                    <ejs-grid-wrapper
                      ref="groupGrid"
                      :dataSource="groupList"
                      :provides="groupGridProvides"
                      :columns="groupGridColumns"
                      :isInPopup="true"
                      :allowPaging="true"
                      :pageSettings="gridPageSetting"
                      @rowSelected="groupGridRowSelected"
                      @recordClick="onGroupGridRecordClick"
                      @queryCellInfo="groupQueryCellInfo"
                      @onGridDialogDoubleClickedOrEnterKeyed="
                        groupGridDialogDoubleClickedOrEnterKeyed
                      "
                      @dataBound="onGroupGridDataBound"
                    />
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="confirm keyColor">
              <erp-button
                  button-div="GET"
                  :is-icon-custom="true"
                  @click.native="groupConfirm"
              >
                확인
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="$refs.groupPopup.hide()">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <group-use-detail-popup
      v-if="isGroupUseDetailPopup"
      ref="groupUseDetailPopup"
      @popupClosed="onGroupUseDetailPopupClosed"
    />
  </div>
</template>

<script>
import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";

import { Resize, ForeignKey, Page } from "@syncfusion/ej2-vue-grids";

import { maxBy as _maxBy, minBy as _minBy } from "lodash";
import { commonCodesGetCommonCode } from "@/utils/commonCodes";
import { getGroupList } from "@/api/group";
import InputText from "@/components/common/text/InputText";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import { getFormattedPhoneNumber } from "@/utils/string";
import GroupUseDetailPopup from "@/views/golf-reservation/popup/GroupUseDetailPopup.vue";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "GroupPopup",
  props: [],
  components: {
    InputText,
    ejsGridWrapper,
    GroupUseDetailPopup,
    ErpButton,
  },
  mixins: [confirmDialogMixin],
  data: function () {
    return {
      isGroupUseDetailPopup: false,
      gridPageSetting: { pageSize: 50 },
      selectedGroup: {},
      groupSearchData: {
        grpKind: null,
        searchValue: null,
        useFlag: true,
        resveDateFrom: null,
        resveDateTo: null,
      },
      grpKindOptions: commonCodesGetCommonCode("GRP_KIND"),
      popupData: {},
      tempGrpName: null,
      groupGridProvides: [Resize, ForeignKey, Page],
      groupGridColumns: [
        {
          field: "grpKind",
          headerText: "단체종류",
          type: "string",
          isCommonCodeField: true,
          groupCode: "GRP_KIND",
          minWidth: 16,
          width: 90,
        },
        {
          field: "grpNo",
          headerText: "단체번호",
          isPrimaryKey: true,
          type: "string",
          minWidth: 16,
          width: 120,
        },
        {
          field: "resveFlagColumn",
          headerText: "등록",
          type: "string",
          minWidth: 16,
          width: 60,
        },
        {
          field: "grpName",
          headerText: "단체명",
          type: "string",
          minWidth: 16,
          width: 180,
        },
        {
          field: "groupUseCount",
          headerText: "사용",
          type: "number",
          format: "N",
          minWidth: 16,
          width: 60,
          textAlign: "center",
        },
        {
          field: "teamCnt",
          headerText: "팀수",
          type: "string",
          minWidth: 16,
          width: 60,
          textAlign: "right",
        },
        {
          field: "areaCode",
          headerText: "지역",
          type: "string",
          isCommonCodeField: true,
          groupCode: "AREA_CODE",
          minWidth: 16,
          width: 90,
        },
        {
          field: "generName",
          headerText: "총무",
          type: "string",
          minWidth: 16,
          width: 90,
        },
        {
          field: "generContactTel",
          headerText: "총무 연락처",
          type: "string",
          minWidth: 16,
          width: 120,
          valueAccessor: this.telNumberValueAccess,
        },
        {
          field: "grpCharct",
          headerText: "비고",
          type: "string",
          minWidth: 16,
        },
      ],
      groupList: [],
    };
  },
  computed: {
    isPopupOpened() {
      return (
        this.isGroupUseDetailPopup
      );
    }
  },
  mounted: function () {
    // 단체종류 option에 전체 추가
    this.grpKindOptions.unshift({
      comCode: "%",
      comName: "전체",
      codeAbrv: "",
      defaultFlag: false,
    });
    this.groupSearchData.grpKind = "%";

    // 단체종류 default 값 설정 - 해야 하나?
    /*
    let grpKindDefaultOption = this.grpKindOptions.find(options => options.defaultFlag === true)
    if (grpKindDefaultOption) {
      this.groupSearchData.grpKind = grpKindDefaultOption.comCode
    }
    */
  },
  methods: {
    commonCodesGetCommonCode: commonCodesGetCommonCode,
    telNumberValueAccess(field, data) {
      let tel = data[field];
      if (tel != null) {
        tel = getFormattedPhoneNumber(tel);
      }
      return tel;
    },
    openPopup: function (popupData) {
      console.log('popupData.===>', popupData);
      this.popupData = popupData;
      this.groupSearchData.searchValue = popupData.grpName;
      this.groupSearchData.grpKind = popupData.grpKind || "%";
      this.tempGrpName = popupData.tempGrpName;

      if (popupData?.resveDates && popupData.resveDates.length > 0) {
        this.groupSearchData.resveDateFrom = _minBy(popupData.resveDates);
        this.groupSearchData.resveDateTo = _maxBy(popupData.resveDates);
      }

      if (popupData.grpName || popupData.grpKind === "TEMP") {
        this.onSearchGolfGroup();
      }
      this.$refs.groupPopup.show();

      this.$refs.searchValueTextBox.focusIn();
    },
    onPopupClosed: function () {
      this.$emit("popupClosed", { popupData: this.popupData });
    },
    onSearchGolfGroupClicked: function () {
      this.selectedGroup = {};
      this.onSearchGolfGroup();
    },
    onSearchGolfGroup: function () {
      // TODO : 단체 정보 조회 API 호출
      console.log(JSON.stringify(this.groupSearchData, null, 2));
      getGroupList(this.groupSearchData)
        .then((response) => {
          this.groupList = response.value.groupInfoList;
        })
        .catch((error) => {
          console.log("getGroupList.err.===>", error);
        });
    },
    groupGridRowSelected: function (args) {
      const {
        data,
        rowIndex,
        previousRowIndex,
        previousRow,
      } = args;
      this.selectedGroup = data;

      if (!data["tempGrpName"] && this.tempGrpName && previousRow !== undefined) {
        if (data["grpName"] === this.groupList.filter(item => item.grpNo === data["grpNo"])[0]?.grpName) {
          this.$refs.groupGrid.setCellValue(data["grpNo"], "grpName", data["grpName"] + "(" + this.tempGrpName + ")");
        }
      }

      if (previousRowIndex !== undefined && !(previousRowIndex < 0)) {
        if (rowIndex !== previousRowIndex) {
          const preGrpNo = previousRow.cells[2].textContent;
          const preGrpName = this.groupList.filter(item => item.grpNo === preGrpNo)[0].grpName;
          this.$refs.groupGrid.setCellValue(preGrpNo, "grpName", preGrpName);
        }
      }
    },
    groupQueryCellInfo: function (args) {
      const {
        column: {
          field,
        },
        data,
        cell,
      } = args;

      if (args.column.field === "resveFlagColumn" && args.data.resveFlag) {
        // 등록 cell style
        args.cell.style.background = "#ED1C24";
      }

      if (field === "groupUseCount") {
        if (data[field] > 0) {
          cell.classList.add(this.$t("className.grid.clickArea"));
        } else if (data[field] === 0) {
          cell.innerText = "-";
        }
      }
    },
    groupGridDialogDoubleClickedOrEnterKeyed() {
      this.groupConfirm();
    },
    groupConfirm: function () {
      if (this.selectedGroup.grpNo) {
        this.selectedGroup.grpName = this.selectedGroup.tempGrpName ? this.selectedGroup.tempGrpName : (this.tempGrpName ? this.tempGrpName : this.selectedGroup.grpName);

        this.$emit("popupClosed", {
          popupData: this.popupData,
          selectedGroup: this.selectedGroup,
        });
      } else {
        this.errorToast("단체를 선택해 주십시오");
      }
    },
    onSearchValueKeyDownEnter() {
      this.onSearchGolfGroup();
    },
    onGroupGridDataBound() {
      if (this.groupList.length > 0) {
        this.$refs.groupGrid.selectRow(0);
      } else {
        document.getElementById("searchValueTextBox").select();
      }
    },
    onGroupGridRecordClick(args) {
      const {
        column: {
          field,
        },
        rowData,
      } = args;

      if (field === "groupUseCount") {
        if (rowData["groupUseCount"] > 0) {
          this.isGroupUseDetailPopup = true;
          this.$nextTick(() => {
            this.$refs.groupUseDetailPopup.showPopup({
              bsnDateFrom: this.groupSearchData.resveDateFrom,
              bsnDateTo: this.groupSearchData.resveDateTo,
              grpNo: rowData.grpNo,
            });
          });
        }
      }
    },
    onGroupUseDetailPopupClosed() {
      this.isGroupUseDetailPopup = false;
    },
    /* ,
    cancelReservation: function () {
      if (this.cancelResnForm.validate()) {
        // 저장된 값을 보내야 하므로 grid save 처리
        this.$refs.cancelGrid.ej2Instances.editModule.batchSave()

        // TODO : 취소 API 호출
        this.cancelInfo.timeList = this.cancelTimeList

        // console.log(JSON.stringify(this.cancelInfo, null, 2))
        this.$emit('popupClosed', { isCanceled: true, popupData: this.popupData })
      }
    } */
  },
};
</script>
