<template>
  <div>
    <ejs-dialog
      ref="groupUseDetailPopup"
      header="사용중인 단체 조회"
      position="{ x: 'center', y: 'center' }"
      :allowDragging="true"
      :showCloseIcon="true"
      :isModal="true"
      width="800"
      height="500"
      @close="onPopupClose"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookup">
              <div class="lookup-left">
                <ul class="lookup-condition">
                  <li class="field">
                    <div class="title">기준일자</div>
                    <ul class="content">
                      <li class="item text">
                        {{ searchOptions.bsnDateFrom === searchOptions.bsnDateTo ? searchOptions.bsnDateFrom : searchOptions.bsnDateFrom + " ~ " + searchOptions.bsnDateTo }}
                      </li>
                    </ul>
                  </li>
                  <li class="field">
                    <div class="title">단체번호</div>
                    <ul class="content">
                      <li class="item text">
                        {{ searchOptions.grpNo }}
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">사용 목록</div>
                      <div class="header-caption">[{{ numberWithCommas(groupUseListCount) }}건]</div>
                    </div>
                  </div>
                  <div class="section-body" style="border: none">
                    <div class="body-grid">
                      <ejs-grid-wrapper
                        ref="grid"
                        v-bind="gridProps"
                        :dataSource="groupUseList"
                      />
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="close">
              <erp-button
                button-div="CLOSE"
                @click.native="onCloseButtonClicked"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import commonMixin from '@/views/layout/mixin/commonMixin';
import confirmDialogMixin from '@/views/layout/mixin/messagePopupDialogMixin';
import routeViewMixin from '@/views/layout/mixin/routeViewMixin';

import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import ErpButton from "@/components/button/ErpButton.vue";

import {Edit, ExcelExport, Filter, ForeignKey, Page, Resize, Selection} from "@syncfusion/ej2-vue-grids";
import { numberWithCommas } from '@/utils/number';
import GolfErpAPI from "@/api/v2/GolfErpAPI";

export default {
  name: "GroupUseDetailPopup",
  components: {
    EjsGridWrapper,
    ErpButton,
  },
  mixins: [commonMixin, confirmDialogMixin, routeViewMixin],
  data() {
    return {
      searchOptions: {
        bsnDateFrom: null,
        bsnDateTo: null,
        grpNo: null,
      },
      groupUseList: [],
      groupUseListCount: 0,
    };
  },
  created() {},
  beforeDestroy() {},
  computed: {
    gridProps() {
      return {
        provides: [Edit, Filter, ForeignKey, Page, Selection, Resize, ExcelExport],
        isInPopup: true,
        allowFiltering: false,
        allowSorting: false,
        allowPaging: true,
        selectionSettings: {
          type: "Single",
          mode: "Both",
          enableToggle: false,
          persistSelection: false,
        },
        editSettings: {
          allowEditing: false,
          mode: "Batch",
          showConfirmDialog: false,
        },
        pageSettings: {pageSize: 50},
        columns: [
          {
            field: "selectDiv",
            headerText: "구분",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
          },
          {
            field: "resveDate",
            headerText: "예약일자",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
          },
          {
            field: "resveCourseName",
            headerText: "예약코스",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
          },
          {
            field: "resveTime",
            headerText: "예약시간",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
          },
          {
            field: "resveName",
            headerText: "예약자",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
          },
          {
            field: "grpNo",
            headerText: "단체번호",
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
          },
          {
            field: "grpName",
            headerText: "단체명",
            type: "string",
            minWidth: 16,
            textAlign: "center",
          },
        ],
      };
    },
  },
  async mounted() {},
  methods: {
    numberWithCommas,
    showPopup(args) {
      console.log('args.===>', args);
      this.searchOptions.bsnDateFrom = args.bsnDateFrom;
      this.searchOptions.bsnDateTo = args.bsnDateTo;
      this.searchOptions.grpNo = args.grpNo;

      this.fetchGroupInUse();
    },
    async fetchGroupInUse() {
      const data = await GolfErpAPI.fetchGroupInUse(this.searchOptions.bsnDateFrom, this.searchOptions.bsnDateTo, this.searchOptions.grpNo);

      this.groupUseList = data;
    },
    onPopupClose() {
      this.$emit("popupClosed");
    },
    onCloseButtonClicked() {
      this.$refs.groupUseDetailPopup.hide();
    },
  },
};
</script>
