<template>
  <div>
    <ejs-dialog
      ref="historyPopup"
      :header="`변경이력`"
      :allowDragging="true"
      :showCloseIcon="true"
      :isModal="true"
      :close="onHistoryPopupClose"
      width="480"
      height="600"
      :animationSettings="{ effect: 'None' }"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <section class="article-section">
                  <div class="section-body" style="border-top-color: #000">
                    <div
                      v-for="(history, index) in $_histories"
                      v-bind:key="`histories-${index}`"
                    >
                      <template
                        v-for="method in ['create', 'update', 'delete']"
                      >
                        <div
                          v-if="history[method]"
                          v-bind:key="`history-${index}-${method}`"
                        >
                          <div style="border-bottom: 1px solid #e0e0e0">
                            <div
                              style="
                                background-color: #f9f9f9;
                                padding: 9px 18px 8px 18px;
                                color: #000;
                              "
                            >
                              ▷ {{ getMethodName(method) }}
                            </div>

                            <div style="padding: 6px 18px">
                              <div
                                style="
                                  color: #000;
                                  padding: 9px 0 8px 0;
                                  border-bottom: 1px solid #e0e0e0;
                                "
                              >
                                <div
                                  style="
                                    box-sizing: border-box;
                                    float: left;
                                    padding-right: 6px;
                                  "
                                >
                                  {{ history.workerName }}
                                </div>
                                <div
                                  style="
                                    box-sizing: border-box;
                                    float: right;
                                    padding-left: 6px;
                                  "
                                >
                                  {{ history.timestamp }}
                                </div>
                                <div style="clear: both; height: 0"></div>
                              </div>
                              <div style="padding: 9px 0 8px 0">
                                <template
                                  v-for="type in getKeysOfObject(
                                    history[method]
                                  )"
                                >
                                  <div
                                    v-if="history[method][type]"
                                    v-bind:key="`history-${index}-${method}-${type}`"
                                  >
                                    <div
                                      v-for="(
                                        typeHistory, typeHistoryIndex
                                      ) in history[method][type]"
                                      v-bind:key="`history-${index}-${method}-${type}-${typeHistoryIndex}`"
                                    >
                                      <div style="font-weight: bold">
                                        {{ getTypeName(type, typeHistory) }}
                                      </div>
                                      <template
                                        v-for="typeHistoryId in getKeysOfObject(
                                          typeHistory
                                        )"
                                      >
                                        <div
                                          v-for="(
                                            typeHistoryChange,
                                            typeHistoryChangeIndex
                                          ) in typeHistory[typeHistoryId]
                                            .changes"
                                          v-bind:key="`history-${index}-${method}-${type}-${typeHistoryIndex}-${typeHistoryId}-${typeHistoryChangeIndex}`"
                                        >
                                          <template
                                            v-if="
                                              method !== 'delete' ||
                                              getFieldOldValue(
                                                type,
                                                typeHistoryChange
                                              ) !== ''
                                            "
                                          >
                                            <div
                                              style="
                                                box-sizing: border-box;
                                                float: left;
                                                width: 153px;
                                                color: #000;
                                                padding-right: 6px;
                                              "
                                            >
                                              <template
                                                v-if="
                                                  typeHistoryChangeIndex ===
                                                    0 &&
                                                  getFieldLabel(
                                                    type,
                                                    typeHistoryChange
                                                  )
                                                "
                                              >
                                                {{
                                                  getFieldLabel(
                                                    type,
                                                    typeHistoryChange
                                                  )
                                                }}
                                              </template>
                                              <template v-else>
                                                &nbsp;
                                              </template>
                                            </div>
                                            <div
                                              style="
                                                box-sizing: border-box;
                                                float: left;
                                                width: 103px;
                                                color: #000;
                                                padding-right: 6px;
                                              "
                                            >
                                              {{
                                                getFieldName(
                                                  type,
                                                  typeHistoryChange
                                                )
                                              }}
                                            </div>
                                            <div
                                              style="
                                                box-sizing: border-box;
                                                float: left;
                                                width: calc(100% - 256px);
                                                color: #666;
                                                padding-left: 6px;
                                              "
                                              :style="{
                                                textDecoration:
                                                  method === 'delete'
                                                    ? 'line-through'
                                                    : undefined,
                                              }"
                                            >
                                              {{
                                                getFieldOldValue(
                                                  type,
                                                  typeHistoryChange
                                                )
                                              }}
                                              <template
                                                v-if="method !== 'delete'"
                                              >
                                                →
                                                {{
                                                  getFieldNewValue(
                                                    type,
                                                    typeHistoryChange
                                                  )
                                                }}
                                              </template>
                                            </div>
                                            <div
                                              style="clear: both; height: 0"
                                            ></div>
                                          </template>
                                        </div>
                                      </template>
                                    </div>
                                  </div>
                                </template>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="onCloseButtonClick"> 닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import { groupBy as _groupBy } from "lodash";
import { commonCodesGetComName } from "@/utils/commonCodes";
import { numberWithCommas } from "@/utils/number";
import { interpretRawHistories } from "@/utils/history/HistoryUtil";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "HistoryPopup",
  components: {
    ErpButton,
  },
  data() {
    return {
      histories: [],
      fields: {},
    };
  },

  computed: {
    $_histories() {
      return this.histories
        .map((history) => {
          let filteredHistory;

          ["create", "update", "delete"].forEach((method) => {
            if (!history[method]) {
              return;
            }

            Object.keys(history[method]).forEach((type) => {
              const typeHistory = history[method][type]
                ?.map((log) => ({
                  ...log,
                  changes: log.changes.filter(({ field }) =>
                    this.getField(type, field)?.methods?.includes(method)
                  ),
                }))
                ?.filter(({ changes }) => changes.length !== 0);

              if (typeHistory && 0 < typeHistory.length) {
                if (!filteredHistory) {
                  filteredHistory = {};
                }
                if (!filteredHistory[method]) {
                  filteredHistory[method] = {};
                }

                filteredHistory[method][type] = _groupBy(
                  typeHistory,
                  "typeAndId"
                );
              }
            });
          });

          if (!filteredHistory) {
            return;
          }

          return {
            ...history,
            ...filteredHistory,
          };
        })
        .filter((history) => !!history);
    },
  },
  methods: {
    commonCodesGetComName,
    show({ rawHistories, fields }) {
      this.histories = interpretRawHistories(rawHistories);
      this.fields = fields;

      this.$refs.historyPopup.show();
    },

    getMethodName(method) {
      switch (method) {
        case "create":
          return "신규";
        case "update":
          return "변경";
        case "delete":
          return "삭제";
      }

      return "";
    },
    getKeysOfObject(object) {
      return Object.keys(object);
    },
    getTypeName(type, typeHistory) {
      if (this.fields[type]?.__name__ instanceof Function) {
        return this.fields[type].__name__(type, typeHistory[0]);
      } else {
        return this.fields[type]?.__name__ || "";
      }
    },
    getField(type, field) {
      return this.fields[type][field];
    },
    getFieldLabel(type, typeHistoryChange) {
      return this.getField(type, typeHistoryChange.field)?.label?.();
    },
    getFieldName(type, change) {
      return this.getField(type, change.field)?.name;
    },
    getFieldOldValue(type, change) {
      const field = this.getField(type, change.field);
      if (!field) {
        return "";
      }

      if (change.oldValue === undefined || change.oldValue === null) {
        return "";
      }

      if (field.type === "commonCode") {
        return commonCodesGetComName(field.groupCode, change.oldValue);
      } else if (field.type === "boolean") {
        return `${change.oldValue}` === "true" ? "예" : "아니요";
      } else if (field.type === "number") {
        return numberWithCommas(change.oldValue);
      } else {
        return change.oldValue || "";
      }
    },
    getFieldNewValue(type, change) {
      const field = this.getField(type, change.field);
      if (!field) {
        return "";
      }

      if (change.newValue === undefined || change.newValue === null) {
        return "";
      }

      if (field.type === "commonCode") {
        return commonCodesGetComName(field.groupCode, change.newValue);
      } else if (field.type === "boolean") {
        return `${change.newValue}` === "true" ? "예" : "아니요";
      } else if (field.type === "number") {
        return numberWithCommas(change.newValue);
      } else {
        return change.newValue || "";
      }
    },

    onHistoryPopupClose() {
      this.$emit("popupClosed");
    },
    onCloseButtonClick() {
      this.$refs.historyPopup.hide();
    },
  },
};
</script>

<style scoped></style>
