<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">회원 검색</div>
            <ul class="content">
              <li class="item">
                <input-text
                  ref="memberName"
                  v-model="searchConditions.memberName"
                  @focus="memberNoClear"
                  @keydown.native="onMemberNameEntered"
                />
              </li>
            </ul>
            <div class="title">핸드폰 뒷 4자리</div>
            <ul class="content">
              <li class="item">
                <input-text
                  v-model="searchConditions.contactTelIdx"
                  @focus="memberNoClear"
                  @keydown.native.enter="onContactTelIdxEntered"
                  @blur="onContactTelIdxEntered"
                />
              </li>
            </ul>
            <ul class="content">
              <li class="item button">
                <ul class="button">
                  <li class="search">
                    <erp-button
                      button-div="GET"
                      @click.native="openMemberPopup"
                    >
                      검색
                    </erp-button>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">발생일자</div>
            <ul class="content">
              <li class="item dateRange">
                <input-date-range
                  v-model="searchOccurDateRange"
                  type="lookupDetail-condition"
                />
              </li>
            </ul>
          </li>

        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :is-shortcut-button="true"
              :ignore="isPopupOpened"
              v-on:click.native="onViewButtonClicked"
          >
            조회
          </erp-button>
        </div>
      </div>
      <div class="lookup-right">
        <div class="lookup-detail">
          <erp-button
              button-div="GET"
              :is-icon-custom="true"
              @click.native="searchDetail"
          >
            상세검색
          </erp-button>
        </div>
        <!--
        <ul class="lookup-button">
          <li class="shortcut">
            <ejs-dropdownbutton
              ref='shortcutMenuDropdownButton'
              cssClass='lookup-button-dropdown'
              :items='shortcutMenuItems'>
              바로가기
            </ejs-dropdownbutton>
          </li>
          <li class="etc">
            <ejs-dropdownbutton
              ref='etcMenuDropdownButton'
              cssClass='lookup-button-dropdown'
              :items='etcMenuItems'>
              기타
            </ejs-dropdownbutton>
          </li>
        </ul>
        -->
      </div>
    </div>
    <div class="content-body">
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">위약 목록</div>
              <div class="header-caption">[{{ count }}건]</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="add">
                  <erp-button
                      button-div="SAVE"
                      :ignore="isPopupOpened"
                      :is-icon-custom="true"
                      :is-custom-shortcut-button="true"
                      shortcut-key="blacklistRegistration.shortcuts.add"
                      :shortcut="{key: 'F3'}"
                      @click.native="onBlacklistAddClicked"
                  >
                    추가
                  </erp-button>
                </li>
                <li class="delete">
                  <erp-button
                      button-div="DELETE"
                      :ignore="isPopupOpened"
                      :is-shortcut-button="true"
                      @click.native="deleteBlacklist"
                  >
                    삭제
                  </erp-button>
                </li>
                <li class="print">
                  <erp-button
                      button-div="FILE"
                      @click.native="onClickExcel"
                  >
                    Excel
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <div class="body-grid">
              <!-- TODO : row 선택 유지 -->
              <ejs-grid-wrapper
                ref="blacklistGrid"
                id="blacklistGrid"
                :enableVirtualization="false"
                :provides="blacklistGridOptions.provides"
                :columns="blacklistGridOptions.columns"
                :dataSource="blacklist"
                :gridLines="'Both'"
                :width="'100%'"
                :height="'100%'"
                :rowHeight="24"
                :selectionSettings="blacklistGridOptions.selectionSettings"
                :editSettings="blacklistGridOptions.editSettings"
                :allowPaging="true"
                :allowExcelExport="true"
                :pageSettings="blacklistGridOptions.pageSettings"
                :allowResizing="true"
                :isAutoSelectCell="false"
                @actionComplete="blacklistGridActionComplete"
                @headerCellInfo="blacklistGridCustomiseHeaderCell"
                @queryCellInfo="blacklistGridCustomiseCell"
                @recordClick="blacklistGridRecordClicked"
              />
            </div>
          </div>
        </section>
      </article>
    </div>
    <ejs-dialog
      ref="searchDetailPopup"
      :header="`상세검색`"
      :allowDragging="true"
      :showCloseIcon="true"
      width="387"
      :animationSettings="{ effect: 'None' }"
      :isModal="false"
      :visible="false"
      enableResize="true"
      v-show="isSearchDetailPopupOpen"
    >
      <div class="window lookupDetail-blacklistRegistration">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookupDetail">
              <ul class="lookupDetail-condition">
                <li class="field">
                  <div class="title">영업구분</div>
                  <ul class="content">
                    <li class="item">
                      <ejs-dropdownlist
                        v-model="searchConditions.bsnCode"
                        :dataSource="blacklistOptions.bsnCodeSearch"
                        :allowFiltering="false"
                        :fields="commonCodeFields"
                        cssClass="lookupDetail-condition-dropdown"
                      ></ejs-dropdownlist>
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">위약종류</div>
                  <ul class="content">
                    <li class="item">
                      <ejs-dropdownlist
                        v-model="searchConditions.penltyKind"
                        :dataSource="blacklistOptions.penltyKindSearch"
                        :allowFiltering="false"
                        :fields="commonCodeFields"
                        cssClass="lookupDetail-condition-dropdown"
                      ></ejs-dropdownlist>
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">위약코드</div>
                  <ul class="content">
                    <li class="item">
                      <ejs-dropdownlist
                        v-model="searchConditions.penltyCode"
                        :dataSource="blacklistOptions.penltyCodeSearch"
                        :allowFiltering="false"
                        :fields="commonCodeFields"
                        cssClass="lookupDetail-condition-dropdown"
                      ></ejs-dropdownlist>
                    </li>
                  </ul>
                </li>
                <li class="field">
                  <div class="title">제한범위</div>
                  <ul class="content">
                    <li class="item">
                      <ejs-dropdownlist
                        v-model="searchConditions.penltyLmttScope"
                        :dataSource="blacklistOptions.penltyLmttScopeSearch"
                        :allowFiltering="false"
                        :fields="commonCodeFields"
                        cssClass="lookupDetail-condition-dropdown"
                      ></ejs-dropdownlist>
                    </li>
                  </ul>
                </li>
                <li class="field groupName">
                  <div class="title">단체명</div>
                  <ul class="content">
                    <li class="item input">
                      <input-text
                        ref="grpName"
                        v-model="searchConditions.grpName"
                        @focus="grpNoClear"
                      />
                    </li>
                    <li class="item button">
                      <ul class="button">
                        <li class="search">
                          <erp-button
                            button-div="GET"
                            @click.native="openGroupPopup"
                          >
                            검색
                          </erp-button>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="lookup keyColor">
              <erp-button
                  button-div="GET"
                  :is-shortcut-button="true"
                  :ignore="isPopupOpened"
                  v-on:click.native="onDialogViewButtonClicked"
              >
                조회
              </erp-button>
            </li>
            <li class="reset">
              <erp-button
                  button-div="GET"
                  :is-icon-custom="true"
                  @click.native="onDialogSearchConditionInitClicked">
                초기화
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="onDialogCloseButtonClicked">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <member-select-popup
      v-if="isMemberSelectPopupOpen"
      ref="memberSelectPopup"
      :position="{ x: 'center', y: 'center' }"
      :isModal="true"
      @popupClosed="memberSelectPopupClosed"
      @popupConfirmed="memberSelectPopupConfirmed"
    ></member-select-popup>
    <group-popup
      v-if="isGroupPopupOpen"
      ref="groupPopup"
      @popupClosed="onGroupPopupClosed"
    ></group-popup>
    <blacklist-registration-popup
      v-if="isBlacklistRegistrationPopupOpen"
      ref="blacklistRegistrationPopup"
      @refresh="onBlacklistRegistrationPopupRefreshed"
      @popupClosed="onBlacklistRegistrationPopupClosed"
    ></blacklist-registration-popup>
  </div>
</template>

<style scoped>
body .appContent .lookup-condition .field.brandNameCord .content .item {width: 180px;}
body .appContent .body-article .section-body {overflow: hidden;border: none;}
</style>

<script>
import numberTemplate from "@/views/common/template/GridNumberTemplate";
import { orderBy as _orderBy } from "lodash";
import {
  Edit,
  VirtualScroll,
  Resize,
  ForeignKey,
  Page,
  ExcelExport,
} from "@syncfusion/ej2-vue-grids";
import memberSelectPopup from "@/views/common/MemberSelectPopup";
import groupPopup from "./popup/GroupPopup";
import blacklistRegistrationPopup from "./popup/BlacklistRegistrationPopup";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import InputDateRange from "@/components/common/datetime/InputDateRange";
import {
  gridUtilGetMemberNoColumnAccess,
  gridUtilGetTelColumnAccess,
} from "@/utils/gridUtil";
import { numberWithCommas } from "@/utils/number";
import { getDayColorValue } from '@/utils/date';
import {commonCodesGetColorValue, commonCodesGetCommonCode} from '@/utils/commonCodes';
import commonMixin from "@/views/layout/mixin/commonMixin";
import { getBlacklist, deleteBlacklist } from "@/api/blacklist";
import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import InputText from "@/components/common/text/InputText";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import ErpButton from "@/components/button/ErpButton.vue";
import moment from "moment";

export default {
  name: "blacklistRegistration",
  components: {
    InputText,
    memberSelectPopup,
    groupPopup,
    blacklistRegistrationPopup,
    EjsGridWrapper,
    InputDateRange,
    ErpButton,
  },
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  data() {
    return {
      count: 0,
      blacklistLength: 0,
      isSearchDetailPopupOpen: false,
      isMemberSelectPopupOpen: false,
      isGroupPopupOpen: false,
      isBlacklistRegistrationPopupOpen: false,
      searchConditions: {
        contactTelIdx: null,
        memberName: null,
        membershipId: null,
        linkMemberSelect: false,
        bsnCode: "%",
        penltyKind: "%",
        penltyCode: "%",
        occurDeFrom: null,
        occurDeTo: null,
        penltyLmttScope: "%",
        grpName: null,
        grpNo: null,
      },
      blacklistOptions: {
        penltyKind: commonCodesGetCommonCode("PENLTY_KIND", true),
        memberDiv: commonCodesGetCommonCode("MEMBER_DIV", true),
        dwCode: commonCodesGetCommonCode("DW_CODE", true),
        courseCode: commonCodesGetCommonCode("COURSE_CODE", true),
        resveChannel: commonCodesGetCommonCode("RESVE_CHANNEL", true),
        bsnCode: commonCodesGetCommonCode("BSN_CODE", true),
        bsnCodeSearch: commonCodesGetCommonCode("BSN_CODE", true),
        penltyCode: commonCodesGetCommonCode("PENLTY_CODE", true),
        penltyCodeSearch: commonCodesGetCommonCode("PENLTY_CODE", true),
        penltyKindSearch: commonCodesGetCommonCode("PENLTY_KIND", true),
        penltyLmttScope: commonCodesGetCommonCode("PENLTY_LMTT_SCOPE", true),
        penltyLmttScopeSearch: commonCodesGetCommonCode(
          "PENLTY_LMTT_SCOPE",
          true
        ),
        payMethod: commonCodesGetCommonCode("PAY_METHOD", true),
        ddctMethod: commonCodesGetCommonCode("DDCT_METHOD"),
      },
      commonCodeFields: { text: "comName", value: "comCode" },
      numberTemplate: function () {
        return {
          template: numberTemplate,
        };
      },
      blacklist: [],
    };
  },
  computed: {
    isPopupOpened() {
      return (
        this.isMemberSelectPopupOpen ||
        this.isGroupPopupOpen ||
        this.isBlacklistRegistrationPopupOpen
      );
    },
    blacklistGridOptions() {
      return {
        provides: [Edit, VirtualScroll, Resize, ForeignKey, Page, ExcelExport],
        selectionSettings: {
          type: "Multiple",
          mode: "Both",
          enableToggle: false,
        },
        editSettings: {
          allowEditing: true,
          allowAdding: false,
          allowDeleting: false,
          mode: "Batch",
          showConfirmDialog: false,
        },
        pageSettings: { pageSize: 50 },
        columns: [
          {
            field: "penltyKind",
            headerText: "위약종류",
            allowEditing: false,
            type: "string",
            width: 90,
            textAlign: "center",
            isCommonCodeField: true,
            dataSource: this.blacklistOptions.penltyKind,
          },
          // {
          //   field: "penltyId",
          //   headerText: "위약번호",
          //   allowEditing: false,
          //   type: "string",
          //   isPrimaryKey: true,
          //   width: 100,
          //   textAlign: "center"
          // },
          {
            field: "occurDate",
            headerText: "발생일자",
            allowEditing: false,
            type: "string",
            width: 90,
            textAlign: "center",
          },
          {
            field: "memberName",
            headerText: "회원명",
            allowEditing: false,
            type: "string",
            width: 90,
            textAlign: "left",
          },
          {
            field: "memberNo",
            headerText: "회원번호",
            allowEditing: false,
            type: "string",
            width: 90,
            textAlign: "center",
            valueAccessor: gridUtilGetMemberNoColumnAccess,
          },
          {
            field: "memberDiv",
            headerText: "회원구분",
            allowEditing: false,
            type: "string",
            width: 90,
            textAlign: "center",
            isCommonCodeField: true,
            dataSource: this.blacklistOptions.memberDiv,
          },
          {
            field: "memberGrade",
            headerText: "회원등급",
            allowEditing: false,
            type: "string",
            minWidth: 16,
            width: 90,
            textAlign: "center",
            isCommonCodeField: true,
            groupCode: "MEMBER_GRADE",
          },
          {
            field: "contactTel",
            headerText: "연락처",
            allowEditing: false,
            type: "string",
            width: 110,
            textAlign: "center",
            valueAccessor: this.contactTelValueAccess,
          },
          {
            field: "grpName",
            headerText: "단체명",
            allowEditing: false,
            type: "string",
            width: 130,
            textAlign: "Left",
          },
          {
            field: "resveDate",
            headerText: "예약일자",
            allowEditing: false,
            type: "string",
            width: 100,
            textAlign: "center",
            isDateType: true,
          },
          {
            field: "dwCode",
            headerText: "요일",
            allowEditing: false,
            type: "string",
            width: 70,
            textAlign: "center",
            groupCode: "DW_CODE",
            isCommonCodeField: true,
          },
          {
            field: "resveTime",
            headerText: "시간",
            allowEditing: false,
            type: "string",
            width: 70,
            textAlign: "center",
            isTimeType: true,
          },
          {
            field: "resveCourse",
            headerText: "코스",
            allowEditing: false,
            type: "string",
            width: 70,
            textAlign: "center",
            isCommonCodeField: true,
            dataSource: this.blacklistOptions.courseCode,
          },
          {
            field: "cancelChannel",
            headerText: "취소채널",
            allowEditing: false,
            type: "string",
            width: 90,
            textAlign: "center",
            isCommonCodeField: true,
            dataSource: this.blacklistOptions.resveChannel,
          },
          {
            field: "bsnCode",
            headerText: "구분",
            allowEditing: false,
            type: "string",
            width: 70,
            textAlign: "center",
            isCommonCodeField: true,
            dataSource: this.blacklistOptions.bsnCode,
          },
          {
            field: "penltyCode",
            headerText: "위약코드",
            allowEditing: false,
            type: "string",
            width: 90,
            textAlign: "center",
            isCommonCodeField: true,
            dataSource: this.blacklistOptions.penltyCode,
          },
          {
            field: "penltyDiv",
            headerText: "위약구분",
            allowEditing: false,
            type: "string",
            width: 90,
            textAlign: "center",
            isCommonCodeField: true,
            groupCode: "PENLTY_DIV",
          },
          {
            field: "penltyResn",
            headerText: "위약사유",
            allowEditing: false,
            type: "string",
            width: 180,
            textAlign: "left",
          },
          {
            field: "penltyLmttScope",
            headerText: "위약제한범위",
            allowEditing: false,
            type: "string",
            width: 130,
            textAlign: "left",
            isCommonCodeField: true,
            dataSource: this.blacklistOptions.penltyLmttScope,
          },
          {
            field: "punshStartDate",
            headerText: "제재시작일",
            allowEditing: false,
            type: "string",
            width: 100,
            textAlign: "center",
            isDateType: true,
          },
          {
            field: "punshEndDate",
            headerText: "제재종료일",
            allowEditing: false,
            type: "string",
            width: 100,
            textAlign: "center",
            isDateType: true,
          },
          {
            field: "penltyDayCount",
            headerText: "제재일수",
            allowEditing: false,
            type: "number",
            format: "N",
            width: 90,
            textAlign: "center",
          },
          {
            headerText: "마일리지",
            columns: [
              {
                field: "ddctMethod",
                headerText: "차감방법",
                allowEditing: false,
                type: "string",
                width: 100,
                textAlign: "center",
                isCommonCodeField: true,
                dataSource: this.blacklistOptions.ddctMethod,
              },
              {
                field: "ddctScore",
                headerText: "차감점수",
                allowEditing: false,
                type: "number",
                format: "N",
                width: 90,
                textAlign: "Right",
              },
            ]
          },
          {
            field: "penltyAmt",
            headerText: "위약금",
            allowEditing: false,
            type: "number",
            format: "N",
            width: 90,
            textAlign: "Right",
          },
          {
            field: "penltyDem",
            headerText: "벌점",
            allowEditing: false,
            type: "number",
            format: "N",
            width: 80,
            textAlign: "center",
          },
          {
            field: "payMethod",
            headerText: "납입방법",
            allowEditing: false,
            type: "string",
            width: 100,
            textAlign: "center",
            isCommonCodeField: true,
            dataSource: this.blacklistOptions.payMethod,
          },
          {
            field: "payDate",
            headerText: "납입일자",
            allowEditing: false,
            type: "string",
            width: 100,
            textAlign: "center",
            isDateType: true,
          },
          {
            field: "insertName",
            headerText: "등록자",
            allowEditing: false,
            type: "string",
            width: 110,
            textAlign: "left",
          },
          {
            field: "insertDt",
            headerText: "등록일시",
            allowEditing: false,
            type: "string",
            width: 130,
            textAlign: "center",
          },
        ],
      };
    },
    searchOccurDateRange: {
      get: function () {
        return {
          from: this.searchConditions.occurDeFrom,
          to: this.searchConditions.occurDeTo,
        };
      },
      set: function (searchOccurDateRange) {
        this.searchConditions.occurDeFrom = searchOccurDateRange.from;
        this.searchConditions.occurDeTo = searchOccurDateRange.to;
      },
    },
  },
  beforeMount() {
    // 검색 조건 설정
    this.blacklistOptions.bsnCodeSearch.unshift({
      comCode: "%",
      comName: "전체",
    });
    this.blacklistOptions.penltyKindSearch.unshift({
      comCode: "%",
      comName: "전체",
    });
    this.blacklistOptions.penltyCodeSearch.unshift({
      comCode: "%",
      comName: "전체",
    });
    this.blacklistOptions.penltyLmttScopeSearch.unshift({
      comCode: "%",
      comName: "전체",
    });
  },
  mounted() {
    // 기본 포커싱
    this.$refs.memberName.focusIn();

    this.searchConditions.occurDeFrom = moment().format("YYYY-MM-DD");
    this.searchConditions.occurDeTo = moment().format("YYYY-MM-DD");

    this.getBlacklist();
  },
  watch: {
    $route() {
      this.$refs.searchDetailPopup.hide();
      this.isSearchDetailPopupOpen = false;
    },
  },
  methods: {
    numberWithCommas,
    contactTelValueAccess: gridUtilGetTelColumnAccess,
    openMemberPopup() {
      this.isMemberSelectPopupOpen = true;

      let memberData = {
        memberNameNo: this.searchConditions.memberName,
        contactTelIdx: this.searchConditions.contactTelIdx
      };
      this.$nextTick(() => {
        this.$refs.memberSelectPopup.showPopup(memberData);
      });
    },
    openGroupPopup() {
      this.isGroupPopupOpen = true;

      let groupPopupData = {
        grpName: this.searchConditions.grpName,
      };

      this.$nextTick(() => {
        this.$refs.groupPopup.openPopup(groupPopupData);
      });
    },
    memberSelectPopupClosed() {
      this.searchConditions.contactTelIdx = null;
      this.isMemberSelectPopupOpen = false;
    },
    onGroupPopupClosed(event) {
      this.isGroupPopupOpen = false;

      if (event.selectedGroup) {
        this.searchConditions.grpName = event.selectedGroup.grpName;
        this.searchConditions.grpNo = event.selectedGroup.grpNo;
      }
    },
    onBlacklistRegistrationPopupRefreshed() {
      this.getBlacklist();
    },
    onBlacklistRegistrationPopupClosed() {
      this.isBlacklistRegistrationPopupOpen = false;
    },
    memberSelectPopupConfirmed(data) {
      this.isMemberSelectPopupOpen = false;

      this.searchConditions.memberName = data.selectedRowData.memberName;
      this.searchConditions.membershipId = data.selectedRowData.membershipId;
    },
    memberNoClear() {
      this.searchConditions.memberName = null;
      this.searchConditions.membershipId = null;
      this.searchConditions.contactTelIdx = null;
    },
    onMemberNameEntered() {
      if (event.key === "Enter") {
        this.getBlacklist();
      }
    },
    onContactTelIdxEntered() {
      if (this.searchConditions.contactTelIdx.trim()) {
        this.openMemberPopup();
      }
    },
    grpNoClear() {
      this.searchConditions.grpName = null;
      this.searchConditions.grpNo = null;
    },
    async deleteBlacklist() {
      let selectedRows = this.$refs.blacklistGrid.getSelectedRecords();
      if (selectedRows.length === 0) {
        this.errorToast("삭제할 데이터를 선택해 주십시오");
        return;
      } else if (!(await this.confirm("삭제하시겠습니까?"))) {
        return;
      }

      let penltyIds = selectedRows.map((row) => row.penltyId);
      try {
        await deleteBlacklist(penltyIds);
        this.infoToast(this.$t("main.popupMessage.deleted"));
        this.getBlacklist();
      } catch (e) {
        console.error("deleteBlacklist.err.===>", e);
      }
    },
    cancelProductGrid() {
      this.$refs.productGrid.ej2Instances.editModule.batchCancel();
    },
    blacklistGridActionComplete() {
      this.count = numberWithCommas(
        this.$refs.blacklistGrid?.getGridBatchCount() || 0
      );
    },
    blacklistGridCustomiseHeaderCell(args) {
      if (
        args.cell.column.field === "memberName" ||
        args.cell.column.field === "memberNo"
      ) {
        args.node.classList.add(this.$t("className.grid.clickArea"));
      }
    },
    blacklistGridCustomiseCell(args) {
      // 클릭영역 셀 처리
      if (
        args.column.field === "memberName" ||
        args.column.field === "memberNo"
      ) {
        args.cell.classList.add(this.$t("className.grid.clickArea"));
      } else if (args.column.field === "dwCode") {
        args.cell.style.color = getDayColorValue(args.data.dwCode, args.data.calenderBsnCode);
      } else if (args.column.field === "bsnCode") {
        args.cell.style.color = commonCodesGetColorValue("BSN_CODE", args.data.bsnCode);
      } else if (args.column.field === "penltyDayCount") {
        args.cell.innerText = args.data.penltyDayCount > 0
          ? `${args.data.penltyDayCount}일`
          : '';
      } else if (args.column.field === "penltyAmt" && args.data.penltyAmt < 1) {
        args.cell.innerText = '-';
      } else if (args.column.field === "penltyDem" && args.data.penltyDem < 1) {
        args.cell.innerText = '-';
      } else if (args.column.field === "ddctScore" && args.data.ddctScore < 1) {
        args.cell.innerText = "-";
      }
    },
    blacklistGridRecordClicked(args) {
      const {
        column: {
          field
        },
        rowData
      } = args;

      if (field === "memberName" || field === "memberNo") {
        this.isBlacklistRegistrationPopupOpen = true;

        // List에서 값을 그대로 보내므로, 서로간의 값이 혼동이 없도록 depp copy하여 보냄
        this.$nextTick(() => {
          this.$refs.blacklistRegistrationPopup.openPopup(
            JSON.parse(JSON.stringify(rowData))
          );
        });
      }
    },
    searchDetail() {
      this.isSearchDetailPopupOpen = true;
      this.$refs.searchDetailPopup.show();
    },
    onBlacklistAddClicked() {
      this.isBlacklistRegistrationPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.blacklistRegistrationPopup.openPopup(null);
      });
    },
    async onProductSaveClicked() {},
    onDialogViewButtonClicked() {
      if (
        (this.searchConditions.occurDeFrom !== null &&
          this.searchConditions.occurDeTo === null) ||
        (this.searchConditions.occurDeFrom === null &&
          this.searchConditions.occurDeTo !== null)
      ) {
        this.errorToast(
          "발생일자는 둘 다 입력하시거나, 둘 다 입력하지 않아야 합니다"
        );
        return;
      }
      this.getBlacklist();
    },
    onDialogCloseButtonClicked() {
      this.$refs.searchDetailPopup.hide();
      this.isSearchDetailPopupOpen = false;
    },
    onDialogSearchConditionInitClicked() {
      this.searchConditions = {
        memberName: null,
        membershipId: null,
        linkMemberSelect: true,
        bsnCode: "%",
        penltyKind: "%",
        penltyCode: "%",
        occurDeFrom: null,
        occurDeTo: null,
        penltyLmttScope: "%",
        grpName: null,
        grpNo: null,
      };

      this.blacklistOptions.occurDeFrom = null;
      this.blacklistOptions.occurDeTo = null;
    },
    onViewButtonClicked() {
      this.getBlacklist();
    },
    async getBlacklist() {
      try {
        let param = {
          memberName:
            this.searchConditions.memberName &&
            !this.searchConditions.membershipId
              ? this.searchConditions.memberName
              : null,
          membershipId: this.searchConditions.membershipId
            ? this.searchConditions.membershipId
            : null,
          linkMemberSelect: this.searchConditions.linkMemberSelect,
          bsnCode:
            this.searchConditions.bsnCode === "%"
              ? null
              : this.searchConditions.bsnCode,
          penltyKind:
            this.searchConditions.penltyKind === "%"
              ? null
              : this.searchConditions.penltyKind,
          penltyCode:
            this.searchConditions.penltyCode === "%"
              ? null
              : this.searchConditions.penltyCode,
          occurDeFrom: this.searchConditions.occurDeFrom
            ? this.searchConditions.occurDeFrom
            : null,
          occurDeTo: this.searchConditions.occurDeTo
            ? this.searchConditions.occurDeTo
            : null,
          penltyLmttScope:
            this.searchConditions.penltyLmttScope === "%"
              ? null
              : this.searchConditions.penltyLmttScope,
          grpName:
            this.searchConditions.grpName && !this.searchConditions.grpNo
              ? this.searchConditions.grpName
              : null,
          grpNo: this.searchConditions.grpNo
            ? this.searchConditions.grpNo
            : null,
        };
        const { value } = await getBlacklist(param);
        this.blacklist = _orderBy(value.blacklistList, "occurDate", ["desc"]);

        this.blacklistLength = value.blacklistList.length;
      } catch (e) {
        console.error("getBlacklist.err.===>", e);
      }
    },
    onClickExcel() {
      this.$refs.blacklistGrid.excelExport();
    },
  },
};
</script>
